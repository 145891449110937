import styles from "./styles/UnpublishedPageInfobox.module.scss";

type UnpublishedInfoBoxProps = {
    unpublishedItemType: string;
};

export function UnpublishedInfoBox({ unpublishedItemType }: UnpublishedInfoBoxProps) {
    return <div className={styles.container}>
        <div>
            {`This ${unpublishedItemType} is unpublished! Only editors and admins can view this content.`}
        </div>
    </div>
}
