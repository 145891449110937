import {useEffect, useState, MutableRefObject} from "react";
import * as React from "react";
import useClickOutside from "../../hooks/useClickOutside";
import styles from "./styles/SimplePopupEditor.module.scss";
import Button from "./Button";
import FontAwesome from "./FontAwesome";

const Editable: React.FC<{
    initialValue: string,
    handleSave: (string) => void,
    rows?: number,
    editable: boolean,
}> = (props) => {

    const [editing, setEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.initialValue);
    const [left, setLeft] = useState<number>(0);

    const ref: MutableRefObject<any> = useClickOutside(() => {
        setValue(props.initialValue); // reset value
        setEditing(false)
    });

    useEffect(() => {
        if (ref?.current) {
            const rect = ref.current?.getBoundingClientRect();
            const l = rect?.width/2 - 150;
            setLeft(l < 0 ? 0 : l);
        }
    }, [ref]);

    function handleSave() {
        props.handleSave(value);
        setEditing(false);
    }

    function handleClick() {
        if (!editing && props.editable) {
            setEditing(true);
        }
    }

    return <span className={styles.container} ref={ref} onClick={handleClick}>
        {editing && <div style={{left}} className={styles.popup}>
            <textarea rows={props.rows || 10} onChange={e => setValue(e.target.value)} value={value} />
            <div>
                <Button onClick={handleSave}>
                    <FontAwesome prefix={'fas'} name={'fa-check'}/>
                </Button>
                <Button onClick={() => setEditing(false)}>
                    <FontAwesome prefix={'fas'} name={'fa-times'}/>
                </Button>
            </div>
            <div />
        </div>}
        {props.children}
    </span>
}

export default Editable;
