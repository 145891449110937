import {createRef, Component} from "react";
import classNames from "classnames";
import {observer} from "mobx-react";
import styles from "./styles/megaNav.module.scss";
import MainLink from "./MainLink";
import Dropdown from "./Dropdown";
import {StoreContext} from "../../../../stores/StoreLoader";
import FontAwesome from "../../../utilities/FontAwesome";
import HelpScout from "../../../support/HelpScout";
import {getDistrictUrl} from "../../../../utils/SchoolBlocksUtilities";
import {reaction} from "mobx";

@observer
class MegaNav extends Component {

    static contextType = StoreContext;

    constructor(props) {
        super(props);

        this.mainLinkRefs = new Map();

        this.state = {
            activeDropdown: null,
            availableYAbove: null,
            availableYBelow: null,
            activeMainlink: null,
        };

        this.hoverTime = 250;
        this.hoverTimeout = null;
        this.hoverLeaveTimeout = null;

        this.containerRef = createRef();
    }

    componentDidMount() {
        this.disposer = reaction(() => this.context.interfaceStore.currentFullUrl, this.closeDropdown);
    }

    componentWillUnmount() {
        this.disposer();
    }

    openDropdown = (evt, link, keypress) => {
        const {interfaceStore} = this.context;
        if (evt && link.children.length > 0) {
            const rect = evt.target.getBoundingClientRect();
            const preservedValues = {
                activeDropdown: link.id,
                activeMainlink: this.mainLinkRefs.get(link.id).getDomElement(),
                availableYAbove: rect.y,
                availableYBelow: interfaceStore.dimensions.height - rect.y, // need to know open space below current = total - y
                keypress, // when keypress is on, it will automatically focus on the first dropdown link when dropdown is opened
            };
            this.hoverLeaveTimeout = setTimeout(() => {
                this.setState(preservedValues);
            }, this.hoverTime);

        }
        clearTimeout(this.hoverTimeout);
    };

    closeDropdown = () => {
        this.hoverTimeout = setTimeout(() => {
            this.setState({
                activeDropdown: null,
            });
        }, this.hoverTime);
        clearTimeout(this.hoverLeaveTimeout);
    };

    moveFocusRight = () => {
        const listItems = document.getElementsByClassName("mainLink")
        const activeItem = document.activeElement
        for (let i = 0; i < listItems.length; i++) {
            const listLength = listItems.length
            if (activeItem === listItems[i] && activeItem !== listItems[listLength - 1]) {
                listItems[i + 1].focus()
            }
        }
    }

    moveFocusLeft = () => {
        const listItems = document.getElementsByClassName("mainLink")
        const activeItem = document.activeElement
        for (let i = 0; i < listItems.length; i++) {
            if (activeItem === listItems[i] && activeItem !== listItems[0]) {
                listItems[i - 1].focus()
            }
        }
    }

    render() {
        const {organizationStore, styleStore} = this.context;

        let linkObjs = [];
        if (organizationStore.navigation.length > 0) {
            linkObjs = organizationStore.navigationTree
                .filter(item => item.enabled)
                .map(item => {
                    let linkEvents = {
                        onKeyDown: (e) => {
                            if (e.which === 39) {
                                e.preventDefault();
                                this.moveFocusRight()
                            }
                            if (e.which === 37) {
                                e.preventDefault();
                                this.moveFocusLeft()
                            }
                        },
                    };
                    if (item.children.length > 0) {
                        linkEvents = {
                            onMouseEnter: e => {
                                this.openDropdown(e, item);
                            },
                            onTouchStart: e => {
                                if (this.state.activeDropdown !== item.id) {
                                    this.openDropdown(e, item);
                                } else {
                                    this.closeDropdown();
                                }
                            },
                            onKeyDown: (e) => {
                                if (e.which === 13 || e.which === 40 || e.which === 32) { // enter, down arrow, space bar
                                    e.preventDefault();
                                    this.openDropdown(e, item, true);
                                }
                                if (e.which === 39) {
                                    e.preventDefault();
                                    this.moveFocusRight()
                                }
                                if (e.which === 37) {
                                    e.preventDefault();
                                    this.moveFocusLeft()
                                }
                            },
                            onMouseLeave: () => {
                                this.closeDropdown();
                            },
                        }
                    }
                    return <MainLink
                        expanded={item.id === this.state.activeDropdown && item.children.length > 0}
                        hasPopup={item.children.length > 0}
                        key={item.id}
                        ref={r => this.mainLinkRefs.set(item.id, r)}
                        events={linkEvents || {}}
                        navigationObj={item}
                    />;
                });
        }

        const dropDownEvents = { // dropdown events (stored here)
            onMouseEnter: () => {
                this.openDropdown();
            },
            onMouseLeave: () => {
                this.closeDropdown();
            },
        };

        const cdMorphDropdownClassName = classNames({
            [styles.cdMorphDropdown]: true,
        });
        const mainNavClassName = classNames({
            [styles.mainNav]: true,
            ['sb-content-width-plus-margin']: styleStore.themeID === '1',
        });

        const districtUrl = getDistrictUrl(organizationStore.organization);

        return (
            <>
                <div ref={this.containerRef} className={cdMorphDropdownClassName}>
                    <nav className={mainNavClassName} id={'navigation'} role={"navigation"}>
                        {districtUrl && <ul style={{float: "left"}} role={"menubar"} aria-label={"District Navigation Menu"}>
                            <li className={styles.mainLink} role={"none"}>
                                <FontAwesome prefix={'fas'} name={'fa-university'} ariaHidden />
                                <a href={districtUrl} role={"menuitem"}>
                                    District
                                </a>
                            </li>
                        </ul>}
                        <ul style={{float: districtUrl ? "right" : "none"}} role={"menubar"}>
                            {linkObjs}
                            {this.state.activeDropdown && <Dropdown
                                activeDropdown={this.state.activeDropdown}
                                events={dropDownEvents}
                                activeMainlink={this.state.activeMainlink}
                                availableYAbove={this.state.availableYAbove}
                                availableYBelow={this.state.availableYBelow}
                                containerRef={this.containerRef}
                                closeDropdown={this.closeDropdown}
                                keypress={this.state.keypress}
                            />}
                        </ul>
                    </nav>

                </div>
                <HelpScout/>
            </>
        );
    }
}

export default MegaNav
