import { useContext, useMemo } from 'react';
import classNames from "classnames";
import styles from "./styles/FooterCS.module.scss";
import {StoreContext} from "../../stores/StoreLoader";
import {observer} from "mobx-react";
import ClickableLink from "../utilities/ClickableLink";
import FontAwesome from "../utilities/FontAwesome";
import FlatIcon from "../utilities/FlatIcon";
import SafeRouter from "../utilities/SafeRouter";
import { UserController } from "../../backends/YiiRoutes";
import { useTranslation } from 'react-i18next';
import { isSchoolBlocksApp } from "../../utils/SchoolBlocksUtilities";
import URI from "urijs";
import {withoutLocaleInURI} from "../../internationalization/i18nURI";
import {ITerraceTypes} from "../blocks/TerracedGridTheme/Terrace";

const cleversiteAdminLinks = [
    {
        title: 'account',
        target: 'Account',
        icon: 'flaticon-admin',
    },
    {
        title: "social feeds",
        target: "Social",
        icon: "flaticon-social-media",
    },
    {
        title: "features",
        target: "Features",
        icon: "flaticon-shapes-and-symbols",
    },
    {
        title: "navigation",
        target: "Navigation",
        icon: "flaticon-website",
    },
    {
        title: "design",
        target: "Design",
        icon: "flaticon-artist",
    },
    {
        title: "domain",
        target: "Domain",
        icon: "flaticon-computer",
    },
];

const adminColumnClassName = classNames({
    [styles.adminColumn]: true,
});

const FooterAccountColumn = observer(() => {
    const { t } = useTranslation('translation');
    const {userStore,  i18nStore} = useContext(StoreContext);
    const params = {
        "following": true,
    }


    const schoolblocksAdminLinks = useMemo(() => {
        const links = [
            {
                title: t("My Calendar"),
                target: UserController.myCalendar(i18nStore.currentOrgSupportedLocale),
                icon: "fas fa-calendar-alt",
            },
            {
                title: t("My SchoolFeed"),
                target: new URI(withoutLocaleInURI("/schoolfeed")).setSearch(params).toString(),
                icon: "fas fa-rss",
            },
            {
                title: t("My Information"),
                target: UserController.settings(i18nStore.currentOrgSupportedLocale),
                icon: "fas fa-cog",
            },
        ];
        if (userStore.isAdmin) {
            links.splice(3, 0, {
                title: t("Configuration"),
                target: "/cms/admin/organizationSettings",
                icon: "fas fa-cog",
            });
        }
        return links;
    }, [userStore.isAdmin]);

    let links;
    if (isSchoolBlocksApp()) {
        links = schoolblocksAdminLinks.map((l, i) => <li key={i}>
            <ClickableLink title={l.title} href={l.target}>
                <FontAwesome ariaHidden={true} name={l.icon} /> {l.title}
            </ClickableLink>
        </li>)
    } else {
        links = cleversiteAdminLinks.map((l, i) => <li key={i}>
            <button onClick={() => SafeRouter.setQuery("spview", l.target)} title={l.title}>
                <FlatIcon ariaHidden={true} name={l.icon}/> {l.title}
            </button>
        </li>)
    }

    return (
        <div className={adminColumnClassName}>
            <h3>{t('ACCOUNT')}</h3>
            <ul>
                {links}
            </ul>
        </div>
    )
});

export default FooterAccountColumn;
