import { useContext } from 'react';
import {observer} from "mobx-react";
import {getPlatform, getSocialIcon} from "../../utils/StringUtilities";
import classNames from "classnames";
import styles from "./styles/FooterCS.module.scss";
import {StoreContext} from "../../stores/StoreLoader";
import ClickableLink from "../utilities/ClickableLink";
import FontAwesome from "../utilities/FontAwesome";
import FlatIcon from "../utilities/FlatIcon";
import {isSchoolBlocksApp} from "../../utils/SchoolBlocksUtilities";

const socialColumnClassName = classNames({
    [styles.navColumn]: true,
});

const FooterSocialColumn = observer(props => {
    const {organizationStore, interfaceStore} = useContext(StoreContext);

    // filter out sources that don't have titles
    let socialItems = [];
    if (isSchoolBlocksApp()) {
        const {social} = organizationStore.organization.json_data.settings;
        if (social?.twitter) {
            socialItems.push(<li key={'social-twitter'} className={"notranslate"}>
                <ClickableLink title={"Twitter"} href={social.twitter}>
                    <FontAwesome ariaHidden={true} prefix={'fab'} name={'fa-x-twitter'}/> Twitter
                </ClickableLink>
            </li>)
        }
        if (social?.facebook) {
            socialItems.push(<li key={'social-facebook'} className={"notranslate"}>
                <ClickableLink title={"Facebook"} href={social.facebook}>
                    <FontAwesome ariaHidden={true} prefix={'fab'} name={'fa-facebook-f'}/> Facebook
                </ClickableLink>
            </li>)
        }
        if (social?.instagram) {
            socialItems.push(<li key={'social-instagram'} className={"notranslate"}>
                <ClickableLink title={"Instagram"} href={social.instagram}>
                    <FontAwesome ariaHidden={true} prefix={'fab'} name={'fa-instagram'}/> Instagram
                </ClickableLink>
            </li>)
        }
        if (social?.youtube) {
            socialItems.push(<li key={'social-youtube'} className={"notranslate"}>
                <ClickableLink title={"Youtube"} href={social.youtube}>
                    <FontAwesome ariaHidden={true} prefix={'fab'} name={'fa-youtube'}/> Youtube
                </ClickableLink>
            </li>)
        }
        if (social?.linkedin) {
            socialItems.push(<li key={'social-linkedin'} className={"notranslate"}>
                <ClickableLink title={"LinkedIn"} href={social.linkedin}>
                    <FontAwesome ariaHidden={true} prefix={'fab'} name={'fa-linkedin'}/> LinkedIn
                </ClickableLink>
            </li>)
        }
    } else {
        socialItems = organizationStore.content_sources.filter(source => source.account_title).map(source => {
            return <li key={source.id} className={"notranslate"}>
                <ClickableLink title={source.account_title} href={source.source_url}>
                    <FlatIcon ariaHidden={true} name={getSocialIcon(getPlatform(source.type))}/> {source.account_title}
                </ClickableLink>
            </li>
        })
    }

    return (
        <div className={socialColumnClassName}>
            {socialItems.length > 0 && <h3>FOLLOW US</h3>}
            <ul>
                {socialItems}
                {isSchoolBlocksApp() && <li key={'privacy-link'}>
                    <ClickableLink title={'Privacy Policy'} href={'https://www.schoolblocks.com/privacy.html'}>
                        <FontAwesome ariaHidden={true} prefix={'fas'} name={'fa-lock'}/> Privacy Policy
                    </ClickableLink>
                </li>}
            </ul>
        </div>
    )
});

export default FooterSocialColumn;
