import {Component, useContext} from "react";
import URI from 'urijs';

import {StoreContext} from "@soprisapps/components/stores/StoreLoader";
import JSONWebToken from "@soprisapps/components/utils/JSONWebToken";
import {PageHead} from "@soprisapps/components/components/header/PageHead";
import {SchoolBlocksPageWrapper} from "@soprisapps/components/pages/SchoolBlocksPageWrapper";
import {getCookieFromString} from "@soprisapps/components/utils/StringUtilities";
import LanguageTool from "@soprisapps/components/components/header/LanguageTool/LanguageTool";
import {HTML5Backend} from "react-dnd-html5-backend";
import dynamic from "next/dynamic";
import {IView} from "@soprisapps/components/stores/InterfaceStore";

const ErrorPage = dynamic(() => import("@soprisapps/components/pages/ErrorPage"));
const DndProvider = dynamic(() => import("react-dnd").then(module => module.DndProvider));
const AdminSidebar = dynamic(() => import("@soprisapps/components/components/admin/sidebar/AdminSidebar"))

function DragAndDropProvider(props) {
    const {userStore} = useContext(StoreContext);
    if (userStore.isEditor) {
        return <DndProvider backend={HTML5Backend}>
            {props.children}
        </DndProvider>
    } else {
        return props.children;
    }
}

class GridPage extends Component {
    constructor(props, context) {
        super(props);

        if (props.jwt) {
            const jwt = new JSONWebToken();
            const token = jwt.decode(props.jwt);
            context.userStore.updateUser(token.user);
        }
    }
    static contextType = StoreContext;

    render() {
        if (this.props.view === IView.ERROR) {
            return <ErrorPage statusCode={this.props.statusCode} />
        }

        try {
            return <DragAndDropProvider>
                <PageHead currentFullUrl={this.props.currentFullUrl} currentTitle={this.props.currentOrganization.title} />
                <LanguageTool />
                <SchoolBlocksPageWrapper {...this.props} />
                {(this.context.userStore.editor || this.props.view === IView.SCHOOLFEED_PAGE) && <AdminSidebar/>}
            </DragAndDropProvider>
        } catch (error) {
            return <ErrorPage />
        }
    }
}

const isServerReq = req => !req.url.startsWith('/_next');


export async function getServerSideProps(ctx) {
    const {req, resolvedUrl, locale} = ctx;
    const skipLookupPaths = ['/service-worker.js', '/manifest.webmanifest', '/favicon.ico', '/_offline'];
    const resolveParentPaths = ['/robots.txt', '/sitemap.xml', '/manifest.webmanifest'];
    const currentBaseUrl = "https://" + req.headers.host.replace(/\/$/, "");

    const returnProps = {
        currentBaseUrl,
        locale,
        userAgent: req.headers['user-agent'],
    }

    const parsedUri = URI(resolvedUrl);
    const isServerSideRequest = isServerReq(req);
    const fullPath = parsedUri.removeQuery("all");
    const resource = fullPath.resource() || "/";
    const parsedQuery = parsedUri.search(true);
    const currentFullUrl = currentBaseUrl + resource;

    const token = getCookieFromString("token", req.headers.cookie);

    try {
        if (!skipLookupPaths.includes(resource) && typeof req !== "undefined") {
            const promises = [new Promise(async (res, rej) => {
                try {

                    const expand = []

                    if (isServerSideRequest || process.env.NODE_ENV === "development") {
                        expand.push(
                            "title_organization",
                            "title_organization.locations",
                            "title_organization.navigation",
                            "title_organization.categories",
                            "title_organization.media",
                            "title_organization.primary_hostname",
                            "title_organization.district",
                            "title_organization.district.navigation",
                            "title_organization.district.primary_hostname",
                        )
                    }

                    const uri = URI(`https://api.${process.env.APP_ENV}.cleversite.com/lookups/`)
                        .search({
                            url: resolveParentPaths.includes(resource) ? currentBaseUrl : currentFullUrl,
                            expand: expand.join(","),
                            gridType: parsedQuery.gridType ? parsedQuery.gridType : undefined,
                        })
                        .normalize()
                        .toString()

                    const headers = {
                        "app-ctx": process.env.NEXT_PUBLIC_APP_CTX,
                    };

                    if (token) {
                        headers["api-key"] = token;
                    }

                    const rawResponse = await fetch(uri, {
                        headers,
                    });
                    const response = await rawResponse.json();

                    const resolveObject = {
                        currentOrganization: response,
                        view: response.view || IView.ERROR,
                        notifications: response.alerts || [],
                        jwt: response.jwt || null,
                    };

                    if (response.blocks) {
                        resolveObject.blocks = response.blocks;
                        delete response.blocks;
                    }
                    if (response.teachers) {
                        resolveObject.teachers = response.teachers;
                        delete response.teachers;
                    }
                    if (response.category_tree) {
                        resolveObject.categoryTree = response.category_tree;
                        delete response.category_tree;
                    }
                    if (response.staff_collection) {
                        resolveObject.staffCollection = response.staff_collection;
                        delete response.staff_collection;
                    }
                    if (response.calendars) {
                        resolveObject.calendars = response.calendars;
                        delete response.calendars;
                    }
                    if (response.user) {
                        resolveObject.user = response.user;
                        delete response.user;
                    }

                    if ((isServerSideRequest || process.env.NODE_ENV === "development") && response.title_organization) {
                        // on client-side transitions we don't need to replace title_organization
                        resolveObject.organization = response.title_organization;
                        delete response.title_organization;
                    }
                    res(resolveObject);
                } catch (e) {
                    rej(e);
                }
            })];

            const responses = await Promise.all(promises);
            responses.forEach(r => Object.assign(returnProps, r));

        }

        console.log("VIEW: ", returnProps.view);

        return {
            notFound: false,
            props: {
                ...returnProps,
                view: resolvedUrl === "/_offline" ? "offline" : returnProps.view,
            },
        }

    } catch (error) {
        console.error(error);
        return {
            props: {
                statusCode: error?.status || 404,
                view: IView.ERROR,
            },
        }
    }
}
export default GridPage;
